// In plugins/axios.js
export default function ({ app, $axios, redirect }) {
    $axios.onError((error) => {
        if (error.response && error.response.status === 401) {
            app.$auth.reset();
            redirect("/login");
        }
        return Promise.reject(error);
    });

    // Add request interceptor to include device ID if available
    $axios.onRequest((config) => {
        if (process.client) {
            const deviceId = localStorage.getItem("auth_device_id");
            if (deviceId) {
                config.headers["X-Device-ID"] = deviceId;
            }
        }
        return config;
    });
}
